import React from "react"
import { graphql} from "gatsby"
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Header from "../components/Header"
import Footer from "../components/Footer"
import Faq from "../components/Faq"
import Accordion from "../components/Accordion"

export default ({ data }) => {
  // const page = data.strapiCenturionInfoData
  const page = data.strapiCenturionInfoData
  let sources = []
  if (page.heroImage) {
    sources = [
      page.heroImage.localFile.childImageSharp.mobileImage,
      {
        ...page.heroImage.localFile.childImageSharp.desktopImage,
        media: `(min-width: 676px)`,
      },
    ]
  }
  return (
    <Layout>
      <SEO metaTitle={page.metaTitle} />
      <Header />
      {page.heroImage &&
        <div className="hero">
          <Img fluid={sources} />
        </div>
      }
      <div className="container container--wide island--dubs">
        <h1  className={page.heroImage ? 'h2 text-center': 'h2'}>{page.title}</h1>
        <div dangerouslySetInnerHTML={{__html: page.content}} />
        {page.faqs &&
          <Faq faqs={page.faqs} />
        }
      </div>
      {page.accordion &&
        <div class="container">
          <Accordion accordion={page.accordion} />
        </div>
      }
      <Footer />
    </Layout>
  )
}
// strapiCenturionInfoData(url: {eq: $url}) {
export const query = graphql`
query ($url: String!) {
  strapiCenturionInfoData(url: {eq: $url}) {
    url
    metaTitle
    title
    heroImage {
      localFile {
				childImageSharp {
          desktopImage: fluid(maxWidth: 1200, maxHeight: 420) {
            ...GatsbyImageSharpFluid
          }
          mobileImage: fluid(maxWidth: 676, maxHeight: 540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    content
    faqs {
      question
      answer
    }
    accordion {
      title
      body
    }
  }
}

`
