import React from "react"
import PropTypes from "prop-types"
import scrollTo from 'gatsby-plugin-smoothscroll'

const Faq = ({ faqs }) => {
  return (
    <div className="faq-block">
      <ul className="faq-index island">
        {faqs.map( (faq, index) => {
          return (
            <li key={index}>
              <button className="item-link" onClick={() => scrollTo("#faq" + index)}>{faq.question}</button>
            </li>
          )
        })}
      </ul>
      <div className="faq-list">
        {faqs.map( (faq, index) => {
          return (
            <div className="item island--dubs" key={index}>
              <div id={"faq" + index} className="anchor"></div>
              <div className="item-title">
                {faq.question}
              </div>
              <div className="item-contents" dangerouslySetInnerHTML={{__html: faq.answer}} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

Faq.propTypes = {
  faqs: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Faq
