import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image/withIEPolyfill"
import { Carousel } from "react-responsive-carousel"

export default class Accordion extends React.Component {
  constructor() {
    super()
    this.state = { openPanel: null }
  }

  togglePanel = (index) => {
    this.setState((prevState) => {
      return { openPanel: prevState.openPanel === index ? null : index}
    })
  }

  render() {
    const { accordion } = this.props
    return (
      <div className="accordion">
        {accordion.map( (panel, index) => {
          return (
            <div key={index} className={this.state.openPanel === index ? 'collapsible open': 'collapsible'}>
              <button className="dropdown" onClick={() => this.togglePanel(index)}>
                <h2 className="h3">{panel.title}</h2>
                <i className="icon-ios-arrow-right"></i>
              </button>
              <div className="dropdown-content">
                <div className="container island">
                  {panel.body &&
                    <div dangerouslySetInnerHTML={{__html: panel.body}} />
                  }
                  {panel.steps &&
                    <Carousel className="step-carousel" showStatus={false} showThumbs={false}>
                      {panel.steps.map( (step, index) => {
                        return (
                          <div key={index} className="step">
                            <div className="step-img">
                              <Img fluid={step.stepImage.localFile.childImageSharp.fluid} alt={"step " + step.number} />
                            </div>
                            <div className="step-desc">
                              <h3 className="h2">{step.number}</h3>
                              <p>{step.description}</p>
                            </div>
                          </div>
                        )
                      })}
                    </Carousel>
                  }
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}

Accordion.propTypes = {
  accordion: PropTypes.arrayOf(PropTypes.object).isRequired,
}
